import React, { useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import Gallery from '../Gallery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import useStyles from '../../themes/masterStyles';
import PopUpImage from '../PopUpImage';

const options = [{
    section: 'decks',
    displayName: 'Award-Winning Decks',
}, {
    section: 'customhomes',
    displayName: 'Custom Homes & Garages',
}, {
    section: 'remodels',
    displayName: 'Full Home Remodels',
}, {
    section: 'kitchens',
    displayName: 'Kitchen & Millwork',
}];

const OurWork = () => {

    const classes = useStyles();

    const [selectionIndex, setSelectionIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState('');

    const handleOpenImage = (publicId) => {
        setCurrentImage(publicId);
    }

    const handleNavigate = (direction) => {
        setSelectionIndex((selectionIndex + direction + options.length) % options.length);
    };

    return (
        <div className={classes.ourWork} style={{marginTop: '2rem'}}>
            <Typography variant="h3" className={classes.ourWorkHeader} style={{textAlign: 'center'}}>Our Work</Typography>
            <div className={classes.underline}></div>
            <div className={classes.gallerySelection}>
                <IconButton onClick={() => handleNavigate(-1)}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography className={classes.ourWorkSelections} variant="h5">
                    {options[selectionIndex].displayName}
                </Typography>
                <IconButton onClick={() => handleNavigate(1)}>
                    <ArrowForwardIcon />
                </IconButton>
            </div>
            <div className={classes.gallery}>
                <Gallery section={options[selectionIndex].section} handleOpenImage={handleOpenImage} />
                <PopUpImage currentImage={currentImage} setCurrentImage={setCurrentImage} />
            </div>
        </div>
    )
}

export default OurWork;