import { Container, MuiThemeProvider } from "@material-ui/core";
import { Switch, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import EstimateForm from "./ui/components/estimateForm";
import Header from "./ui/components/Layout/header";
import Main from "./ui/components/Layout/main"
import OurWork from "./ui/components/Work/OurWork";
import theme from './ui/themes/muiTheme'

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <Container maxWidth="lg">
            <Header />
            <Switch>
              <Route path="/" exact>
                <Main />
              </Route>
              <Route path="/contact">
                <EstimateForm />
              </Route>
              <Route path="/our-work">
                <OurWork />
              </Route>
            </Switch>
          </Container>
        </MuiThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;