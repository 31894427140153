import { React } from 'react';
import Carousel from 'react-bootstrap/Carousel'
import SSIMG1 from '../../../assets/images/slideShow/1.jpeg'
import SSIMG2 from '../../../assets/images/slideShow/6.jpeg'
import SSIMG3 from '../../../assets/images/slideShow/3.jpeg'
import SSIMG4 from '../../../assets/images/slideShow/4.jpeg'
import SSIMG5 from '../../../assets/images/slideShow/clean6.jpg'
import SSIMG6 from '../../../assets/images/slideShow/clean7.jpg'
import useStyles from '../../themes/masterStyles';

const SlideShow = () => {

    const classes = useStyles();

    return (
        <Carousel interval={6000} controls={false}>
            <Carousel.Item>
                <img
                    className={classes.bannerImage}
                    src={SSIMG5}
                    alt="Fifth slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className={classes.bannerImage}
                    src={SSIMG6}
                    alt="Sixth slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className={classes.bannerImage}
                    src={SSIMG1}
                    alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className={classes.bannerImage}
                    src={SSIMG2}
                    alt="Second slide"
                    style={{objectPosition: '0% 80%'}} />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className={classes.bannerImage}
                    src={SSIMG3}
                    alt="Third slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className={classes.bannerImage}
                    src={SSIMG4}
                    alt="Fourth slide" />
            </Carousel.Item>
        </Carousel>
    )
}

export default SlideShow;