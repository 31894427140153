import { createMuiTheme } from '@material-ui/core/styles';

const font = "'Montserrat', sans-serif";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#fff',
        },
        secondary: {
            main: '#16355e'
        },
    },
    typography: {
        fontFamily: font
    },
    breakpoints: {
        values: {
            xs: 400,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1500,
            xxl: 1700
        }
    },
    overrides: {
        MuiIconButton: {
            root: {
                transition: "0.3s",
                bottom: "0px",
                '&:hover': {
                    backgroundColor: "transparent",
                    bottom: '3px',
                },
                padding: "5px",
            },
            colorSecondary: {
                color: "#16355e",
                transition: "0.3s",
                '&:hover': {
                    backgroundColor: "transparent",
                    color: "#7bc3e2"
                }
            }
        },
        MuiButton: {
            root: {
                '&:hover': {
                    backgroundColor: "transparent"
                },
                minWidth: '8rem'
            },
            label: {
                '&:hover': {
                    fontSize: "11pt",
                    color: "#7bc3e2"
                },
                transition: "0.3s",
            },
            text: {
                padding: "0px"
            }
        },
        MuiDialog: {
            paper: {
                maxWidth: '100vw !important',
                width: '80vw !important',
            }
        }
    }
});

export default theme;