import { Card, CardContent, IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from '../../themes/masterStyles';
import bg from '../../../assets/images/testimonialBG.jpeg'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const Testimonials = () => {

    const classes = useStyles();

    const [selection, setSelection] = useState(0);

    const testimonialArray = [{
        name: 'Adam S.',
        body: '"Matt has done a lot of great work for me on several different homes. Everything from repairing flood damage to a full remodel of a bathroom. Also painting, building a fence. He’s done a lot of great work for us on two different homes. I was extremely happy. His work is great but also he communicates really well. Matt is very professional. He’s on time, gets things done. I had a six week project, and he had it done a week before. And he’s honest. Was in two homes without supervision, I never worried about anything."',
    },{
        name: 'Tracy F.',
        body: '"Matt has been working for me on an ‘as needed’ basis for several years, and I find his work to be outstanding. He always shows up on time and ready to work, and he does whatever I need him to do, from yard maintenance to handyman stuff around the properties to remodel and renovation work. I don’t know what I’d do without Matt… he’s my right hand man and I’ll keep bringing him back for as long as he will put up with me. I would highly recommend him to anyone who needs a good, solid, dependable worker."',
    },{
        name: 'Leif H.',
        body: '"All American did a great job on a large remodel project. We turned a un-used detached garage space into a fantastic home office/studio with a heated garage workspace above and a killer deck. Matt also helped with some remodel work in the main house and continues to give me a hand with work I am unable to do myself. Matt has great ideas, a great work ethic and gets the job done on time and on budget."',
    }];

    const testimonialCard = (testimonial) => (
        <Card className={classes.testimonialCard}>
            <CardContent>
                <Typography className={classes.testimonialName} style={{fontWeight: 'bold'}} variant="h5" gutterBottom>
                    {testimonial.name}
                </Typography>
                <div className={classes.cardUnderline}></div>
                <Typography className={classes.testimonialBody} variant="body2" component="p">
                    {testimonial.body}
                <br />
                </Typography>
            </CardContent>
        </Card>
    );

    const handleBack = () => {
        selection === 0
            ? setSelection(2)
            : setSelection(selection -1)
    };

    const handleForward = () => {
        selection === 2
            ? setSelection(0)
            : setSelection(selection +1)
    };

    return (
        <div className={classes.testimonials}>
            <div>
                <img src={bg} alt="background" className={classes.testimonialBackground} />
                <div className={classes.testimonialBackgroundOverlay} style={{zIndex: -1}}></div>
                <div className={classes.testimonialContent}>
                    <Typography variant="h3" className={classes.testimonialHeader}>Testimonials</Typography>
                    <div className={classes.underline}></div>
                    <div className={classes.testimonialCardContainer}>
                        {testimonialArray.map(t => testimonialCard(t))}
                    </div>
                    <div className={classes.mobileTestimonialCardContainer}>
                        <IconButton color="primary" onClick={handleBack}>
                            <ArrowBackIcon />
                        </IconButton>
                        {testimonialCard(testimonialArray[selection])}
                        <IconButton color="primary" onClick={handleForward}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonials;