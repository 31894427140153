import { React } from 'react';
import Banner from '../Home/banner';
import Services from '../Home/services';
import '../../themes/globalStyles.css';
import Experience from '../Home/experience';
import useStyles from '../../themes/masterStyles';
import { Button } from '@material-ui/core';
import Testimonials from '../Home/testimonials';
import { Link } from 'react-router-dom';

const Main = () => {

    const classes = useStyles();

    return (
        <div>
            <Banner />
            <section className="triangle">
                <Experience />
            </section>
            <Services />
            <section className="semiCircle">
                <div className={classes.quoteButtonDiv}>
                    <Link to="/contact">
                        <Button variant="contained" className={classes.quoteButton}>
                            Contact Us
                        </Button>
                    </Link>
                </div>
            </section>
            <section className="curved">
            </section>
            <Testimonials />
            <section className="curvedUp"></section>
            <div className={classes.quoteButtonDiv} style={{marginBottom: '20rem'}}>
                <Link to="/contact">
                    <Button variant="contained" className={classes.quoteButton}>
                        Contact Us
                    </Button>
                </Link>
            </div>
            {/* <EstimateForm /> */}
        </div>
    );
}

export default Main;