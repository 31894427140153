import React from 'react';
import SlideShow from '../Home/slideShow';
import { Paper, Typography } from '@material-ui/core';
import useStyles from '../../themes/masterStyles';

const Banner = () => {

    const classes = useStyles();

    return (
        <div className={classes.banner}>
            <div>
                <Paper className={classes.bannerCard}>
                    <div className={classes.bannerLeft}>
                        <Typography className={classes.residential} variant="h3">
                            Residential & Commercial
                        </Typography>
                        <Typography className={classes.general} variant="h3">
                            General Contractor
                        </Typography>
                        {/* <Typography className={classes.contractor} variant="h2">
                            Contractor
                        </Typography> */}
                    </div>
                    <div>
                        <Typography className={classes.logLine} variant="h5">
                            We Provide a complete new custom home program and remodeling options
                        </Typography>
                    </div>
                </Paper>
            </div>
            <div className={classes.bannerContainer}>
                <SlideShow />
            </div>
        </div>
    )
}

export default Banner;