import { Cloudinary } from '@cloudinary/url-gen';
import { thumbnail } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { AdvancedImage, placeholder } from '@cloudinary/react';
import { Box } from '@material-ui/core';
import {fill} from "@cloudinary/url-gen/actions/resize";
import { useRef, useState, useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';


const cld = new Cloudinary({
  cloud: {
    cloudName: 'allamericanrestoration',
  },
});
// eslint-disable-next-line react/prop-types
const CldThumbnail = ({ publicId, handleOpenImage }) => {
  const myImage = cld
    .image(publicId)
    .resize(thumbnail().width(200).height(200).gravity(autoGravity()))
    .delivery(format('auto'))
    .delivery(quality('auto'));
  return (
    <Box
        onClick={() => handleOpenImage(publicId)}
        borderRadius='.3rem!important' 
        sx={{ 
            transition: '300ms', 
            '&:hover': { 
                cursor: 'pointer', 
                scale: 1.2
            }
        }}>
        <AdvancedImage
            cldImg={myImage}
            style={{ maxWidth: '100%' }}
            plugins={[placeholder({ mode: 'vectorize' })]}
            className="rounded-lg shadow-lg"
        />
    </Box>
  );
};

const CldFullImage = ({ publicId }) => {
  const [ loading, setLoading ] = useState(true);

  const fullImageRef = useRef();

  const myImage = cld
    .image(publicId)
    .resize(fill().width(1040))
    .delivery(format('auto'))
    .delivery(quality('auto'));

  useEffect(() => {
    if (fullImageRef.current) {
      fullImageRef.current.imageRef.current.addEventListener('load', () => setLoading(false));
    }
  }, [fullImageRef])

  return (
    <Box>
      <AdvancedImage
          cldImg={myImage}
          ref={fullImageRef}
          style={{ maxWidth: '100%', maxHeight: '80vh' }}
          plugins={[placeholder({ mode: 'blur' })]}
          className="rounded-lg"
          id='fullImage'
      />
      {loading && <Skeleton width='100%' height={'calc(100vw * 0.50)'} variant='rect' />}
    </Box>
  );
};

export {
    CldThumbnail,
    CldFullImage,
};
