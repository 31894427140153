import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../../themes/masterStyles';

const Experience = () => {

    const classes = useStyles();

    return (
        <div className={classes.experience}>
            <Paper square className={classes.getQuoteBanner} elevation={6}>
                <Typography className={classes.experienceTypography} variant="h3">
                    Over 25 Years Experience
                </Typography>
            </Paper>

            <Typography className={classes.experienceLogLine} variant="subtitle1">
                Founded by Owner/Operator Matt Laffey in Evergreen Colorado circa 2009, All American Restoration. LLC is a family run and operated business operating exclusively in the Denver Front Range and Foothills.
            </Typography>
            <Typography className={classes.experienceLogLine} variant="subtitle1">
                All American Restoration. LLC employs local contractors that have exceptional customer service and attention to detail as well as combined 25 years experience in the residential new build construction, remodeling, and commercial TI field.
            </Typography>
        </div>
    )
}

export default Experience;