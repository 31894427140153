import React from 'react';
import useStyles from '../../themes/masterStyles';
import { Divider, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons'

const CustomHomes = () => {

    const classes = useStyles();

    return (
        <div className={classes.serviceCard}>
            <FontAwesomeIcon icon={faHome} size="3x" className={classes.serviceIcon} />
            <Typography variant="h4" style={{fontWeight: 'bold'}} className={classes.serviceHeader}>Custom Homes</Typography>
            <Divider style={{marginBottom: 10, backgroundColor: '#fff'}} />
            <div>
                <Typography variant="subtitle1" className={classes.serviceSubHeader}>
                    Owner works directly with clients.
                </Typography>
                <Typography variant="subtitle1" className={classes.serviceSubHeader}>
                    We use licensed architects supervisory personnel.
                </Typography>
            </div>
            <div className={classes.serviceListAndImage}>
                <div className={classes.serviceTypography}>
                    <Typography variant="h5" className={classes.servicesInclude}>
                        Services Include:
                    </Typography>
                    <div className={classes.servicesList}>
                        <Typography className={classes.servicesListItem} variant="body1">Site Analysis</Typography>
                        <Typography className={classes.servicesListItem} variant="body1">Financial Sources</Typography>
                        <Typography className={classes.servicesListItem} variant="body1">Architectural Design</Typography>
                        <Typography className={classes.servicesListItem} variant="body1">Construction</Typography>
                        <Typography className={classes.servicesListItem} variant="body1">Daily Supervision</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomHomes;