import React, {useEffect, useState} from 'react';
import { Box } from '@material-ui/core';
import {CldThumbnail} from './CldImage';

const Gallery = ({ section, handleOpenImage }) => {
    const [ photos, setPhotos ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    
    const getAlbum = async () => {
        const response = await fetch(`https://res.cloudinary.com/allamericanrestoration/image/list/${section}.json`);
        const data = await response.json();
        setPhotos(data);
        setLoading(false);
    };

    useEffect(() => {
        getAlbum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ section ]);

    return loading ? (<Box>Loading...</Box>) : (
        <Box display='flex' flexWrap='wrap' justifyContent='space-around'>
            {photos.resources.map((photo, idx) => {
            return (
              <div className="lg:w-1/3 md:w-1/2 w-full p-4" key={idx}>
                <CldThumbnail publicId={photo.public_id} handleOpenImage={handleOpenImage} />
              </div>
            );
          })}
        </Box>
    );
};

export default Gallery;
