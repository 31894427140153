import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        marginTop: '4.3rem',
        height: '5.3rem',
        paddingLeft: '35px',
        paddingRight: '35px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('md')]: {
            marginTop: '1rem',
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    appBarShift: {
        width: `calc(100% - 200px)`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: '240px',
    },
    logo: {
        position: 'absolute',
        bottom: '17px',
        border: 'none',
        boxShadow: 'none',
        background: 'none',
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            bottom: 0,
            top: 10
        }
    },
    logoImg : {
        [theme.breakpoints.down('md')]: {
            width: '15vw'
        },
        [theme.breakpoints.between('xs', 'md')]: {
            width: '10vw'
        },
        [theme.breakpoints.down('xs')]: {
            width: '20vw'
        }
    },
    menuButton: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        top: 8
    },
    headerLinks: {
        height: '5.3rem',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        alignItems: 'flex-end',
        color: '#16355e',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    bannerContainer: {
        width: '100%',
        maxHeight: '35vw',
        background: 'rgba(0,0,0,1)'
    },
    bannerImage: {
        width: '100%',
        height: '35vw',
        objectFit: 'cover',
        objectPosition: '50%, 70%',
        opacity: 0.8
    },
    bannerCard: {
        position: 'absolute',
        top: '30rem',
        left: '10%',
        width: '80%',
        zIndex: 100,
        background: '#00000063',
        color: 'antiquewhite',
        borderLeft: '7px solid #16355e',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            position: 'static',
            width: '100%',
            background: 'none',
            border: 'none',
            color: 'black',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 15,
            textAlign: 'center'
        },
        [theme.breakpoints.down('md')]: {
            top: '34vw'
        },
        [theme.breakpoints.between('md', 'lg')]: {
            top: '34vw'
        },
        [theme.breakpoints.up('lg')]: {
            top: '28rem',
            left: '8%',
            width: '84%'
        },
        [theme.breakpoints.up('xl')]: {
            top: '32rem',
            left: '14%',
            width: '73%'
        },
        [theme.breakpoints.up('xxl')]: {
            top: '38rem',
            left: '19%',
            width: '60%'
        },

    },
    getQuoteBanner: {
        background: 'linear-gradient(90deg, rgba(64,160,212,1) 0%, rgba(84,187,222,0.654499299719888) 36%, rgba(0,212,255,0.3127626050420168) 80%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    quoteButtonDiv: {
        marginTop: -20,
        textAlign: 'center',
    },
    quoteButton: {
        backgroundColor: '#f4d50f',
        border: '1px solid black',
        borderRadius: '2px',
        minWidth: '14rem',
        '&:hover': {
            backgroundColor: '#fff',
            borderColor: '#16355e !important'
        }
    },
    experience: {
        textAlign: 'center',
    },
    experienceTypography: {
        marginTop: '1.5rem', 
        marginBottom: '1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem'
        }
    },
    experienceLogLine: {
        width: '75%', 
        margin: 'auto', 
        marginTop: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
            marginTop: '0.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.7rem'
        }
    },
    underline: {
        border: '1px solid #f4d50f',
        width: '30%',
        margin: 'auto',
        marginTop: 10,
        marginBottom: '2rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0.5rem',
            marginTop: 5
        }
    },
    services: {
        textAlign: 'center',
        height: '35rem',
        scrollSnapAlign: 'start',
        [theme.breakpoints.down('sm')]: {
            height: '25rem'
        }
    },
    servicesHeader: {
        marginTop: '-2rem', 
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            marginTop: '-1rem'
        }
    },
    servicesBackground: {
        objectFit: 'cover',
        position: 'absolute',
        width: '100%',
        height: '35rem',
        zIndex: -1,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            height: '25rem'
        }
    },
    servicesBackgroundOverlay: {
        background: '#0000009c',
        position: 'absolute',
        height: '35rem',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '25rem'
        }
    },
    serviceCardContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    mobileServiceCardContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    serviceIcon: {
        width: '0.835em !important',
        [theme.breakpoints.down('sm')]: {
            width: '0.7em !important'
        },
        [theme.breakpoints.down('xs')]: {
            height: '0.5em'
        }
    },
    serviceHeader: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
        }
    },
    serviceSubHeader: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem'
        }
    },
    servicesInclude: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        }
    },
    divider: {
        width: '50%',
        margin: 'auto !important',
        marginBottom: 20
    },
    serviceListAndImage: {
        marginTop: 20,
    },
    serviceImage: {
        width: '20rem',
    },
    servicesListItem: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem'
        }
    },
    bannerLeft: {
        width: '80rem',
        [theme.breakpoints.down('md')]: {
            width: '40rem'
        },
        [theme.breakpoints.down('xs')]: {
            width: 'unset'
        }
    },
    residential: {
        marginLeft: '10px',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            fontSize: '1rem'
        }
    },
    general: {
        marginLeft: '50px',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            fontSize: '1rem'
        }
    },
    contractor: {
        marginLeft: '93px !important'
    },
    logLine: {
        marginLeft: '10%',
        marginRight: '5%',
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 10,
            fontSize: '0.8rem'
        }
    },
    serviceCard: {
        width: '50%',
        padding: 20,
        height: '24rem',
        color: '#fff',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '20rem'
        }
    },
    testimonials: {
        position: 'relative',
        marginTop: '-6rem',
        scrollSnapAlign: 'start',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-5rem'
        }
    },
    testimonialHeader: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            marginTop: 20
        }
    },
    testimonialCardContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '4rem',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    testimonialName: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        }
    },
    testimonialBody: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem'
        }
    },
    mobileTestimonialCardContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '2rem',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    testimonialCard: {
        maxWidth: '22rem',
        padding: 15,
        textAlign: 'center',
        transition: 'transform .3s',
        background: '#2b25259e',
        color: 'white',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: ''
        },
        [theme.breakpoints.down('sm')]: {
            padding: 5
        }
    },
    cardUnderline: {
        border: '1px solid #16355e',
        width: '50%',
        margin: 'auto',
        marginTop: 10,
        marginBottom: '1rem'
    },
    testimonialBackground: {
        objectFit: 'cover',
        position: 'absolute',
        width: '100%',
        height: '40rem',
        zIndex: -1,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            height: '28rem'
        }
    },
    testimonialBackgroundOverlay: {
        background: '#0000004d',
        position: 'absolute',
        height: '35rem',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '28rem'
        }
    },
    testimonialContent: {
        zIndex: 10, 
        height: '40rem',
        [theme.breakpoints.down('sm')]: {
            height: '28rem'
        }
    },
    estimateForm: {
        textAlign: 'center',
        marginBottom: 100,
        marginTop: '2rem'
    },
    formContainer: {
        width: '50%',
        margin: 'auto',
        padding: 20,
        paddingLeft: '5rem',
        paddingRight: '5rem',
        background: 'whitesmoke',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    textField: {
        marginBottom: '20px !important'
    },
    iconButton: {
        '&:hover': {
            backgroundColor: 'inherit !important'
        }
    },
    navLinks: {
        width: '22rem',
        marginRight: '5px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    socialMediaLinks: {
        width: '15rem',
        display: 'flex',
        justifyContent: 'space-around'
    },
    ourWork: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    ourWorkHeader: {
        [theme.breakpoints.down('md')]:{
            fontSize: '2rem'
        }
    },
    ourWorkSelections: {
        [theme.breakpoints.down('md')]:{
            fontSize: '1rem'
        }
    },
    gallerySelection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    gallery: {
        width: '100%',
        height: '100%',
    },
    contactHeader: {
        [theme.breakpoints.down('sm')]:{
            fontSize: '2rem'
        }
    },
    contactSubHeader: {
        [theme.breakpoints.down('sm')]:{
            fontSize: '1rem'
        }
    }
}))

export default useStyles;