import React from 'react';
import useStyles from '../../themes/masterStyles';
import { Divider, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

const GeneralContracting = () => {

    const classes = useStyles();

    return (
        <div className={classes.serviceCard}>
            <FontAwesomeIcon icon={faUsers} size="3x" className={classes.serviceIcon} />
            <Typography variant="h4" style={{fontWeight: 'bold'}} className={classes.serviceHeader}>
                General Contracting
            </Typography>
            <Divider style={{marginBottom: 10, backgroundColor: '#fff'}} />
            <Typography variant="subtitle1" className={classes.serviceSubHeader}>
                We employ local contractors that have a combined 25 years experience in the residential remodeling and commercial construction field.
            </Typography>
            <div className={classes.serviceListAndImage}>
                <div className={classes.serviceTypography}>
                    <Typography variant="h5" className={classes.servicesInclude}>
                        Services Include:
                    </Typography>
                    <div className={classes.servicesList}>
                        <Typography className={classes.servicesListItem} variant="body1">Engineering Consultation</Typography>
                        <Typography className={classes.servicesListItem} variant="body1">Interior Design Consultation</Typography>
                        <Typography className={classes.servicesListItem} variant="body1">Architectural Referral</Typography>
                        <Typography className={classes.servicesListItem} variant="body1">Landscaping and hard-scaping</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralContracting;