import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import SendIcon from '@material-ui/icons/Send';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { Link } from 'react-router-dom';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }
}));

const PersistentDrawerRight = ({open, handleDrawerClose}) => {
  const classes = useStyles();
  const theme = useTheme();

  const routes = [
    "/",
    "/our-work",
    "/contact"
  ]

  const routeIcons = [
    <HomeIcon />,
    <PhotoLibraryIcon />,
    <SendIcon />
  ]

  const socialMediaLinks = [
    "https://www.facebook.com/YourHomeRestoration/",
    "https://www.instagram.com/allamericanrestores/",
    "tel:3036706885",
    "mailto:matt@allamericanrestoration.co"
  ]

  const socialMediaIcons = [
    <FacebookIcon />,
    <InstagramIcon />,
    <PhoneIcon />,
    <EmailIcon />
  ]

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Home', 'Our Work', 'Contact'].map((text, index) => (
            <Link key={text} to={routes[index]}>
              <ListItem onClick={handleDrawerClose} button key={text}>
                <ListItemIcon>
                  {routeIcons[index]}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <List>
          {['Facebook', 'Instagram', 'Phone', 'Email'].map((text, index) => (
            <a key={text} href={socialMediaLinks[index]} rel="noreferrer" target="_blank" style={{color: '#16355e'}}>
              <ListItem onClick={handleDrawerClose} button key={text}>
                <ListItemIcon>
                  <IconButton color="secondary" className={classes.iconButton}>
                      {socialMediaIcons[index]}
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </a>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

export default PersistentDrawerRight;