import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../../../assets/images/aarlogo2.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import MenuIcon from '@material-ui/icons/Menu';
import { Hidden, Paper, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from '../../themes/masterStyles';
import clsx from 'clsx';
import PersistentDrawerRight from '../Drawer';

const Header = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={clsx(classes.appBar, {[classes.appBarShift]: open})} >
        <Toolbar>
            <Paper className={classes.logo}>
              <Link to="/">
                <img className={classes.logoImg} src={Logo} alt="Company Logo"/>
              </Link>
            </Paper>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={classes.menuButton + clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Hidden smUp implementation="css">
              {<PersistentDrawerRight open={open} handleDrawerClose={handleDrawerClose} />}
            </Hidden>
            <div className={classes.headerLinks}>
                <div className={classes.socialMediaLinks}>
                    <Tooltip title="Facebook" placement="top" arrow>
                      <IconButton color="secondary" className={classes.iconButton}>
                        <a href="https://www.facebook.com/YourHomeRestoration/" rel="noreferrer" target="_blank" style={{color: '#16355e'}}>
                          <FacebookIcon />
                        </a>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Instagram" placement="top" arrow>
                      <IconButton color="secondary" className={classes.iconButton}>
                        <a href="https://www.instagram.com/allamericanrestores/" rel="noreferrer" target="_blank" style={{color: '#16355e'}}>
                          <InstagramIcon />
                        </a>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Call" placement="top" arrow>
                      <IconButton color="secondary" className={classes.iconButton}>
                        <a href="tel:3036706885" rel="noreferrer" style={{color: '#16355e'}}>
                          <PhoneIcon />
                        </a>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Email" placement="top" arrow>
                      <IconButton color="secondary" className={classes.iconButton}>
                        <a href="mailto:matt@allamericanrestoration.co" rel="noreferrer" target="_blank" style={{color: '#16355e'}}>
                          <EmailIcon />
                        </a>
                      </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.navLinks}>
                    <Link to="/">
                        <Button disableRipple variant="text" color="inherit">Home</Button>
                    </Link>
                    <Link to="/our-work">
                      <Button disableRipple variant="text" color="inherit">Our Work</Button>
                    </Link>
                    <Link to="/contact">
                      <Button disableRipple variant="text" color="inherit">Contact</Button>
                    </Link>
                </div>
            </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;