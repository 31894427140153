import { Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { CldFullImage } from "./CldImage";

const PopUpImage = ({currentImage, setCurrentImage}) => (
    <Dialog open={!!currentImage} onClose={() => setCurrentImage('')}>
        <DialogTitle style={{ padding: '5px' }}>
            <IconButton onClick={() => setCurrentImage('')} style={{ float: 'right' }}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '20px', textAlign: 'center' }}>
            <CldFullImage publicId={currentImage} />
        </DialogContent>
    </Dialog>
);

export default PopUpImage;
