import { IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from '../../themes/masterStyles';
import CustomHomes from './customHomes';
import GeneralContracting from './generalContracting';
import BluePrintBG from '../../../assets/images/blueprintbg.jpeg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const Services = () => {

    const classes = useStyles();
    
    const [mobileSelect, setSelect] = useState(true);

    const handleSelect = () => {
        setSelect(!mobileSelect)
    };

    return (
        <div className={classes.services}>
            <div style={{position: 'relative', width: '100%'}}>
                <img src={BluePrintBG} alt="background" className={classes.servicesBackground} />
                <div className={classes.servicesBackgroundOverlay}></div>
                <div style={{position: 'absolute', zIndex: 10, width: '100%'}}>
                    <Typography variant="h3" className={classes.servicesHeader}>
                      Services
                    </Typography>
                    <div className={classes.underline}></div>
                    <div className={classes.serviceCardContainer}>
                      <CustomHomes />
                      <GeneralContracting />
                    </div>
                    <div className={classes.mobileServiceCardContainer}>
                        <IconButton color="primary" onClick={handleSelect}>
                            <ArrowBackIcon />
                        </IconButton>
                            {mobileSelect ? <CustomHomes /> : <GeneralContracting />}
                        <IconButton color="primary" onClick={handleSelect}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;