import { Button, Divider, LinearProgress, Paper, Snackbar, TextareaAutosize, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../themes/masterStyles';
import emailJS from 'emailjs-com';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import env from "react-dotenv";
import MuiAlert from '@material-ui/lab/Alert';

const EstimateForm = () => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
    }

        setOpen(false);
    };

    const ContactSchema = Yup.object().shape({
        name: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone: Yup.string().min(10, 'Phone Number Must be 10 Digits').max(10, 'Phone Number Must be 10 Digits').required('Required').matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Invalid Phone Number'),
        body: Yup.string().min(1, 'Required')
    });

    const handleMessageSubmit = (values) => {
        const templateParams = {
            from_name: values.name,
            phone: values.phone,
            email: values.email,
            message: values.body
        }

        emailJS.send(env.EMAIL_JS_SERVICE, env.EMAIL_JS_TEMP, templateParams, env.EMAIL_JS_ID)
            .then((result) => {
                setOpen(true);
            }, (error) => {
                console.error(error.text);
            });
    }

    return (
        <div className={classes.estimateForm}>
            <Typography className={classes.contactHeader} variant="h3">
                Ready to get started?
            </Typography>
            <div className={classes.underline}></div>
            <Paper className={classes.formContainer} elevation={6}>
                <Typography className={classes.contactSubHeader} variant="h4" gutterBottom style={{fontWeight: 'bold'}}>
                    Contact Matt Today!
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="subtitle2" style={{marginTop: '1rem'}}>
                    Just fill in the information below and we’ll get back to you as soon as we can. Thanks!
                </Typography>
                <div style={{marginTop: 50}}>
                    <Formik 
                        initialValues={{name: '', email: '', phone: '', body: ''}}
                        validationSchema={ContactSchema}
                        onSubmit={(values) => handleMessageSubmit(values)}
                    >
                        {({ handleSubmit, isSubmitting, handleChange }) => (
                            <Form onSubmit={handleSubmit}>
                                <Field
                                    component={TextField}
                                    name="name"
                                    label="Name"
                                    color="secondary" size="small" fullWidth 
                                    variant="outlined" className={classes.textField}
                                />
                                <br />
                                <Field
                                    component={TextField}
                                    type="email"
                                    label="Email"
                                    name="email"
                                    color="secondary" size="small" fullWidth 
                                    variant="outlined" className={classes.textField}
                                />
                                <Field
                                    component={TextField}
                                    type="phone"
                                    label="Phone"
                                    name="phone"
                                    color="secondary" size="small" fullWidth 
                                    variant="outlined" className={classes.textField}
                                />
                                <Field
                                    as={TextareaAutosize}
                                    label="Message"
                                    name="body"
                                    style={{width: '100%', marginBottom: 20}}
                                    rowsMin={4}
                                    aria-label="maximum height"
                                    placeholder="Type your message here..."
                                />
                                {isSubmitting && <LinearProgress />}
                                <br />
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    className={classes.quoteButton}
                                >
                                    Submit
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success" >
                        Message Sent!
                    </MuiAlert>
                </Snackbar>
            </Paper>
        </div>
    )
}

export default EstimateForm;